import { Component, Input } from '@angular/core';
import { AppMaterialModule } from 'src/app/modules/app-material.module';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  standalone: true,
  imports: [CommonModule, AppMaterialModule],
})
export class LoadingComponent {
  @Input() message = 'Lade...';
}
